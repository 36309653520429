import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ErrorNotification = (message: string) => {
  return (
    <Stack
      className="message-error"
      direction="row"
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
      <CancelIcon />
      <Typography justifyContent="center">
        {message}
      </Typography>
    </Stack>
  )
};

export default ErrorNotification;
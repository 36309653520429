import React from 'react'
import Container from '@mui/material/Container';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Container maxWidth="xl" sx={{ mt: 7 }}>
      <Header/>
      <Outlet />
      <Footer/>
    </Container>
  )
};

export default Layout;

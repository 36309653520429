import { useEffect } from 'react';

const usePageTitle = (title: string) => {
  useEffect(() => {
    // Save the original title
    const originalTitle = document.title;

    // Set the new title
    document.title = `${title} | CRASHLAW`;

    // Cleanup - restore the original title when component unmounts
    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};

export default usePageTitle; 
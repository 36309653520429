import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LmpLogo from "assets/images/lmp_logo.webp";
import { useAuth } from "contexts/AuthProvider";
import usePageTitle from "hooks/usePageTitle";
import Notification from "messages/Notification";
import RegistrationForm from "pages/Register/RegistrationForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [result, setResult] = useState({
    type: "",
    message: "",
  });

  usePageTitle("Create Your Account");

  if (user) {
    navigate("/dashboard");
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ my: 15, maxWidth: "550px", mx: "auto" }}
    >
      {result.type == "success" && Notification("success", result.message)}
      {result.type == "error" &&
        Notification("error", result.message ?? "Something went wrong.")}
      <Typography variant="h6" sx={{ mb: 0, fontWeight: "bold" }}>
        Create Your Account
      </Typography>
      <Box>
        <Typography>
          Enter all of your details below to create an account and you will
          receive an email.
        </Typography>
      </Box>
      <RegistrationForm updateResult={setResult} />
      <Box
        component="img"
        src={LmpLogo} // Replace with the real image URL
        alt="LMP Logo"
        sx={{ width: "110px", mt: 10, filter: "invert(1)" }}
      />
    </Box>
  );
};

export default Register;

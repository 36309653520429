import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useAuth } from "contexts/AuthProvider";
import { Formik } from "formik";
import * as Yup from "yup";
import Notification from "messages/Notification";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import usePageTitle from "hooks/usePageTitle";
import { Label } from "@mui/icons-material";

type MyProfileProps = {
  updateResult: any;
};

export default function ProfilePage() {
  usePageTitle("Profile");
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const [result, setResult] = useState({
    type: "",
    message: "",
  });

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    current_password: Yup.string().required("Current password is required"),
    new_password: Yup.string()
      .min(8, "New password must be at least 8 characters long")
      .optional(),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("new_password")], "Passwords must match")
      .optional(),
  });

  return (
    <>
      <Box>
        <div style={{ marginTop: 30, marginBottom: -20 }}>
          {result.type != "" &&
            Notification(result.type, result.message ?? "Invalid credentials.")}
        </div>

        <Typography variant="h6" sx={{ mt: 10, mb: 0, fontWeight: "bold" }}>
          Your Profile
        </Typography>
        {user && (
          <Formik
            initialValues={{
              email: user.email,
              mobile_number: user.mobile_number,
              current_password: "",
              new_password: "",
              password_confirmation: "",
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              axios
                .post(process.env.REACT_APP_API_URL + "/user/update", values, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(function (response: any) {
                  if (response.status == 200) {
                    setResult({
                      type: "success",
                      message: "Changes saved successfully",
                    });
                  } else {
                    setResult({
                      type: "error",
                      message: response.data.message,
                    });
                  }
                })
                .catch(function (error: any) {
                  console.log(error);
                  setResult({
                    type: "error",
                    message: error.response.data.message,
                  });
                });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="mobile_number"
                  placeholder="Mobile Number"
                  name="mobile_number"
                  onChange={handleChange}
                  value={values.mobile_number}
                  type="text"
                />
                <Typography
                  variant="h6"
                  sx={{ mt: 5, mb: 0, fontWeight: "regular", fontSize: 16 }}
                >
                  (Optional) If you wish to update your password, enter a new
                  password below
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="new_password"
                  placeholder="New Password"
                  name="new_password"
                  onChange={handleChange}
                  value={values.new_password}
                  type="password"
                />
                {errors.new_password && touched.new_password ? (
                  <Typography color="red" textAlign="left">
                    {errors.new_password}
                  </Typography>
                ) : null}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password_confirmation"
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  onChange={handleChange}
                  value={values.password_confirmation}
                  type="password"
                />
                {errors.password_confirmation &&
                touched.password_confirmation ? (
                  <Typography color="red" textAlign="left">
                    {errors.password_confirmation}
                  </Typography>
                ) : null}
                <Typography
                  variant="h6"
                  sx={{ mt: 5, mb: 0, fontWeight: "semibold", fontSize: 16 }}
                >
                  Enter your current password to save changes to your profile
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  id="current_password"
                  placeholder="Current Password"
                  name="current_password"
                  onChange={handleChange}
                  value={values.current_password}
                  type="password"
                />

                <Button
                  type="submit"
                  disabled={isSubmitting || !values.current_password}
                  variant="contained"
                  color="error"
                  size="large"
                  sx={{ my: 2, borderRadius: 10 }}
                >
                  SAVE CHANGES
                </Button>
              </form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
}

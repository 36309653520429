import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactSupportIcon from '@mui/icons-material/Phone';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from 'assets/images/logo.png';
import { useAuth } from 'contexts/AuthProvider';
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

const navigations = [
    { title: 'Home', url: '/', icon: <HomeIcon /> },
    { title: 'How does it work', url: '/how-does-it-work', icon: <HelpIcon /> },
    { title: 'Contact', url: '/contact', icon: <ContactSupportIcon /> },
];

const Header = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderAuthButtons = () => (
        user ? (
            <>
                <Button
                    variant="outlined"
                    sx={{ borderRadius: 28, px: 3 }}
                    onClick={() => navigate('/dashboard')}
                >Dashboard</Button>
                <Button
                    variant="contained"
                    sx={{ borderRadius: 28, px: 3 }}
                    onClick={logout}
                >Logout</Button>
            </>
        ) : (
            <>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: 28,
                        px: 3,
                        color: isMobile ? '#000' : '#fff',
                        borderColor: '#fff',
                        fontWeight: 700,
                    }}
                    component={Link}
                    to={'/register'}
                >Register</Button>
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: 28,
                        px: 3,
                        fontWeight: 700,
                        color: isMobile ? '#000' : '#fff',
                    }}
                    component={Link}
                    to={'/login'}
                >Login</Button>
            </>
        )
    );

    return (
        <AppBar position="static" color="transparent" elevation={0} className="header-menu">
            <Toolbar sx={{ paddingLeft: '0!important', paddingRight: '0!important' }}>
                <Grid sx={{ flexGrow: 1 }}>
                    <Link to="/">
                        <img
                            src={logo}
                            alt="logo"
                            style={{ maxWidth: isMobile ? '200px' : '330px' }}
                        />
                    </Link>
                </Grid>

                {isMobile ? (
                    <>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon sx={{ color: '#fff' }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {navigations.map((nav, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        handleMenuClose();
                                        navigate(nav.url);
                                    }}
                                >
                                    {nav.icon}
                                    <Typography sx={{ ml: 1, color: isMobile ? '#000' : '#fff' }}>{nav.title}</Typography>
                                </MenuItem>
                            ))}
                            {user ? (
                                <>
                                    <MenuItem onClick={() => {
                                        handleMenuClose();
                                        navigate('/dashboard');
                                    }}>
                                        <DashboardIcon />
                                        <Typography sx={{ ml: 1, color: isMobile ? '#000' : '#fff' }}>Dashboard</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleMenuClose();
                                        logout();
                                    }}>
                                        <LogoutIcon />
                                        <Typography sx={{ ml: 1, color: isMobile ? '#000' : '#fff' }}>Logout</Typography>
                                    </MenuItem>
                                </>
                            ) : (
                                <>
                                    <MenuItem onClick={() => {
                                        handleMenuClose();
                                        navigate('/register');
                                    }}>
                                        <PersonAddIcon />
                                        <Typography sx={{ ml: 1, color: isMobile ? '#000' : '#fff' }}>Register</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleMenuClose();
                                        navigate('/login');
                                    }}>
                                        <LoginIcon />
                                        <Typography sx={{ ml: 1, color: isMobile ? '#000' : '#fff' }}>Login</Typography>
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </>
                ) : (
                    <Grid container spacing={2}>
                        <Box sx={{ display: 'flex', gap: 5, flexDirection: 'row', marginRight: 5 }}>
                            {navigations.map((nav, index) => (
                                <Box sx={{ mt: 1, fontStyle: 'none' }} key={index}>
                                    <Link to={nav.url}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {isMobile && (
                                                nav.icon
                                            )}
                                            <Typography>{nav.title}</Typography>
                                        </Box>
                                    </Link>
                                </Box>
                            ))}
                        </Box>
                        {renderAuthButtons()}
                    </Grid>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
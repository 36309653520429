import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Formik } from "formik";
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as Yup from "yup";

type ResetProps = {
  updateResult: any
};

const ResetForm: React.FC<ResetProps> = ({ updateResult }) => {
  const [searchParams] = useSearchParams();
  let { code } = useParams();
  let userId = searchParams.get('id');

  const ValidationSchema = Yup.object().shape({
    password: Yup.string().min(8, "Too Short!").max(50, "Too Long!").required("Required"),
    password_confirmation: Yup.string().min(8, "Too Short!").max(50, "Too Long!").required("Required").oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  return (
    <Box>
      <Formik
        initialValues={{
          id: userId,
          password: '',
          password_confirmation: '',
          code: code,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          axios.post(process.env.REACT_APP_API_URL + '/change-password', values)
            .then(function (response: any) {
              if (response.status == 200) {
                updateResult({
                  type: "success",
                  message: response.data.message,
                });
              } else {
                updateResult({
                  type: "error",
                  message: response.data.message,
                });
              }
            })
            .catch(function (error: any) {
              console.log(error);
              updateResult({
                type: "error",
                message: "Something went wrong.",
              });
            });

          resetForm();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              placeholder="New Password"
              name="password"
              onChange={handleChange}
              value={values.password}
              type="password"
            />
            {errors.password && touched.password ? (
              <Typography color="red" textAlign="left">{errors.password}</Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password_confirmation"
              placeholder="Confirm Password"
              name="password_confirmation"
              onChange={handleChange}
              value={values.password_confirmation}
              type="password"
            />
            {errors.password_confirmation && touched.password_confirmation ? (
              <Typography color="red" textAlign="left">{errors.password_confirmation}</Typography>
            ) : null}
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="error"
              fullWidth={true}
              size="large"
              sx={{ my: 2 }}
            >
              RESET PASSWORD
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ResetForm;
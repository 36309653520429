import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import DownloadIcon from '@mui/icons-material/Download';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useAuth } from 'contexts/AuthProvider';
import usePageTitle from 'hooks/usePageTitle';
import Notification from 'messages/Notification';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const POLLING_INTERVAL = 5000; // 5 seconds between checks
const MAX_POLLING_ATTEMPTS = 24; // 2 minutes total (24 * 5 seconds)
const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;
const PLAY_STORE_URL = process.env.REACT_APP_PLAY_STORE_URL;

const Verification = () => {
    const navigate = useNavigate();
    const { user, token, setUser } = useAuth();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [pollingAttempts, setPollingAttempts] = useState(0);
    const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [isInitialCheckLoading, setIsInitialCheckLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const subscriptionSuccess = queryParams.get('subscription_success') === 'true';
    const subscriptionCancel = queryParams.get('subscription_cancel') === 'true';
    usePageTitle('Verify Your Identity');

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user]);

    useEffect(() => {
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [pollingInterval]);

    useEffect(() => {
        const checkInitialStatus = async () => {
            setIsInitialCheckLoading(true);
            try {
                const [verifyResponse, subscriptionResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/identity/verify/status`, {
                        headers: { Authorization: `Bearer ${token}` }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/subscription/status`, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                ]);

                setIsVerified(verifyResponse.data.verified);
                setHasSubscription(subscriptionResponse.data.hasSubscription);

                if (verifyResponse.data.status === "requires_input") {
                    console.log('Verification requires input, stopping polling');
                    setError('Verification could not be completed. Please try again.');
                    return false; // Signal to stop polling
                }
                if (verifyResponse.data.verified && subscriptionResponse.data.hasSubscription) {
                    navigate('/dashboard');
                }
            } catch (err) {
                setError('Failed to fetch status');
            } finally {
                setIsInitialCheckLoading(false);
            }
        };

        if (user && token) {
            checkInitialStatus();
        }
    }, [user, token]);

    useEffect(() => {
        const handleSubscriptionSuccess = async () => {
            if (subscriptionSuccess && token) {
                try {
                    // Fetch updated user data
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    // Update local storage with new user data
                    const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
                    const updatedUser = {
                        ...currentUser,
                        ...response.data.user,
                    };

                    localStorage.setItem('user', JSON.stringify(updatedUser));
                    setUser(updatedUser);

                    // Show success message
                    Notification("success", "Subscription activated successfully!");

                    // Navigate to dashboard if both verification and subscription are complete
                    if (response.data.user.identity_verified_at && !response.data.needs_subscription) {
                        navigate('/dashboard?subscription_success=true');
                    }
                } catch (err) {
                    setError('Failed to update subscription status');
                }
            }

            if (subscriptionCancel && token) {
                Notification("error", "Subscription process cancelled. Please try again.");
            }
        };

        handleSubscriptionSuccess();
    }, [subscriptionSuccess, token]);

    const checkVerificationStatus = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/identity/verify/status`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const newAttempts = pollingAttempts + 1;
            setPollingAttempts(newAttempts);

            if (response.data.status === "requires_input") {
                console.log('Verification requires input, stopping polling');
                setError('Verification could not be completed. Please try again.');
                return false; // Signal to stop polling
            }

            if (response.data.verified) {
                console.log('Verification successful, stopping polling');
                setIsVerified(true);
                return false; // Signal to stop polling
            }

            if (newAttempts >= MAX_POLLING_ATTEMPTS) {
                console.log('Max attempts reached, stopping polling');
                setError('Verification timeout. Please try again.');
                return false; // Signal to stop polling
            }

            return true; // Signal to continue polling
        } catch (err) {
            console.log('Error occurred, stopping polling');
            setError('Failed to check verification status');
            return false; // Signal to stop polling
        }
    };

    const startPolling = () => {
        setIsChecking(true);
        setPollingAttempts(0);

        const interval = setInterval(async () => {
            const shouldContinue = await checkVerificationStatus();
            if (!shouldContinue) {
                clearInterval(interval);
                setIsChecking(false);
                setPollingInterval(null);
            }
        }, POLLING_INTERVAL);

        setPollingInterval(interval);
    };

    const startVerification = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/identity/verify`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.url) {
                startPolling(); // Start polling before redirecting
                window.location.href = response.data.url;
            }
        } catch (err) {
            setError('Failed to start verification process');
        } finally {
            setIsLoading(false);
        }
    };

    const startSubscription = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/subscriptions`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (err) {
            setError('Failed to start subscription process');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ my: 15, maxWidth: '600px', mx: 'auto' }}
        >

            {error && Notification("error", error)}

            <Typography variant="h5" sx={{ mb: 4 }}>
                {hasSubscription ? 'Setup Complete!' : 'Complete Your Registration'}
            </Typography>

            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                To use CrashLaw24, you must verify your identity and subscribe to our service.
            </Typography>

            <Box sx={{ width: '100%', mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <VerifiedUserIcon sx={{ mr: 1 }} />
                    Step 1: Verify Your Identity
                </Typography>
                <Button
                    onClick={startVerification}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isInitialCheckLoading || isLoading || isChecking || isVerified}
                >
                    {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : isVerified ? (
                        'Verification Complete ✓'
                    ) : isInitialCheckLoading ? (
                        'Loading...'
                    ) : (
                        'Start Verification'
                    )}
                </Button>
                {isChecking && (
                    <Typography sx={{ mt: 1, textAlign: 'center' }}>
                        Checking verification status...
                    </Typography>
                )}
            </Box>

            <Box sx={{ width: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <SubscriptionsIcon sx={{ mr: 1 }} />
                    Step 2: Subscribe to Our Service (£5/month)
                </Typography>
                <Button
                    onClick={startSubscription}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isInitialCheckLoading || !isVerified}
                >
                    {isInitialCheckLoading ? (
                        'Loading...'
                    ) : !isVerified ? (
                        'Complete Verification First'
                    ) : hasSubscription ? (
                        'Subscription Active ✓'
                    ) : (
                        'Start Subscription'
                    )}
                </Button>
            </Box>

            <Box sx={{ width: '100%', mt: 4 }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <DownloadIcon sx={{ mr: 1 }} />
                    Step 3: Download the App
                </Typography>

                <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                    <Button
                        component="a"
                        href={APP_STORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="outlined"
                        size="large"
                        sx={{
                            flex: 1,
                            height: '56px',
                            fontSize: '1rem'
                        }}
                        startIcon={<AppleIcon />}
                    >
                        App Store
                    </Button>
                    <Button
                        component="a"
                        href={PLAY_STORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="outlined"
                        size="large"
                        sx={{
                            flex: 1,
                            height: '56px',
                            fontSize: '1rem'
                        }}
                        startIcon={<AndroidIcon />}
                    >
                        Play Store
                    </Button>
                </Stack>

                <Typography variant="body2" color="white" sx={{ mt: 1, textAlign: 'center' }}>
                    {!isVerified || !hasSubscription ?
                        "Complete verification and subscription to get full access to the app" :
                        "You're all set! Download the app to get started"
                    }
                </Typography>
            </Box>
        </Box>
    );
};

export default Verification; 
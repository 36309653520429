import { useState } from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

const SuccessNotification = (message: string) => {
  return (
    <Stack 
      className="message-success"
      direction="row"
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
      <CheckIcon/>
      <Typography justifyContent="center">
        {message}
      </Typography>
    </Stack>
  )
};

export default SuccessNotification;
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

const navigations = [
    { title: 'Privacy Policy', url: '/privacy-policy' },
    { title: 'Terms & Conditions', url: '/terms-and-conditions' },
];

const Footer = () => {
    return (
        <AppBar position="static" color="transparent" elevation={0} className="header-menu">
            <Toolbar
                sx={{
                    paddingLeft: '0!important',
                    paddingRight: '0!important',
                    flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
                    gap: { xs: 2, sm: 0 }, // Add gap between elements when stacked
                    py: { xs: 2, sm: 0 } // Add vertical padding on mobile
                }}
            >
                <Grid
                    sx={{
                        flexGrow: 1,
                        textAlign: { xs: 'center', sm: 'left' } // Center text on mobile
                    }}
                >
                    <Typography>&copy; LMP Legal Limited 2024</Typography>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }} // Center links on mobile
                >
                    {navigations.map((nav, index) => (
                        <Box key={index} sx={{ px: 1 }}>
                            <Link to={nav.url}><Typography>{nav.title}</Typography></Link>
                        </Box>
                    ))}
                </Grid>
            </Toolbar>
            <Grid
                sx={{
                    flexGrow: 1,
                    textAlign: { xs: 'center', sm: 'right' } // Center text on mobile
                }}
            >
                <a href="https://pragmaticdigital.co.uk" target='_blank'>
                    <Typography style={{ fontSize: 10 }}>Built by Pragmatic Digital</Typography>
                </a>
            </Grid>
        </AppBar>
    )
};

export default Footer;
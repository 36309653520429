import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import usePageTitle from 'hooks/usePageTitle';
import { useNavigate } from 'react-router-dom';

const NoPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  usePageTitle('Page Not Found');

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        px: { xs: 2, sm: 4, md: 6 }, // Responsive padding
        py: { xs: 4, sm: 6, md: 8 }  // Responsive padding
      }}
    >
      <ErrorOutlineIcon
        sx={{
          fontSize: { xs: 80, sm: 120, md: 160 }, // Responsive icon size
          color: 'warning.main',
          mb: { xs: 2, sm: 3, md: 4 }  // Responsive margin
        }}
      />
      <Typography
        variant={isMobile ? 'h2' : 'h1'}
        sx={{
          mb: { xs: 1, sm: 2 },
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: { xs: '3rem', sm: '4rem', md: '6rem' } // Responsive font size
        }}
      >
        404
      </Typography>
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        sx={{
          mb: { xs: 3, sm: 4 },
          color: '#FFF',
          textAlign: 'center',
          px: 2,
          maxWidth: { xs: '100%', sm: '80%', md: '60%' }, // Responsive width
          fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' } // Responsive font size
        }}
      >
        Oops! We couldn't find the page you're looking for!
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          px: { xs: 3, sm: 4 }, // Responsive padding
          py: { xs: 1, sm: 1.5 }, // Responsive padding
          fontSize: { xs: '0.9rem', sm: '1rem' }, // Responsive font size
          borderRadius: 100
        }}
      >
        Back to Homepage
      </Button>
    </Container>
  );
};

export default NoPage;
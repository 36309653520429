import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';

const Notification = (type: string, message: string) => {
  return (
    <>
      {type == "success" && SuccessNotification(message)}
      {type == "error" && ErrorNotification(message)}
    </>
  )
};

export default Notification;